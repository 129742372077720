/* Modal.css */
.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-opaque background */
}

.modal-container {
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #333; /* Gray background color */
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  color: #fff; /* Contrasting text color */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content input[type="file"] {
  margin-bottom: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.modal-content a {
  color: #0dd4dd;
}

.modal-content table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.modal-content th, .modal-content td {
  text-align: center;
  padding: 4px, 8px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 12px 0;
  gap: 12px;
}

.form-group label {
  flex: 2;
  text-align: right;
}

.form-group div {
  flex: 3;
  text-align: left;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0px;
}

.exit-x {
  color: #aaa;
  align-self: flex-end;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.exit-x:hover,
.ext-x:focus {
  color: white;
  text-decoration: none;
}

.submit-button {
  margin-top: 20px;
  align-self: center;
  width: 20%;
  height: 32px;
  background-color:chocolate;
  color: white;
}

.error {
  color: red;
  font-size: 12px;
  margin-left: 12px;
}